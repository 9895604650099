/* Change this file to get your personal Porfolio */

const theme = {
  primaryTheme: "#6C63FF",
  secondaryTheme: "#55198b",
  easing: "cubic-bezier(0.645, 0.045, 0.355, 1)"
};

const greeting = {
	/* Your Summary And Greeting Section */
	//title: "Hi all 👋 I'm Dipesh",
	title: "Dipesh Khanal",
	nickname: "Flutter Developer",
	// subTitle:
	//   "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks",
	subTitle:
		"A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
	resumeLink:
		"https://drive.google.com/file/d/1lb_KjVvZlU9tLW42ZyXTHj59L-3ZNoNt/view?usp=sharing"
};

const socialMediaLinks = {
	/* Your Social Media Link */
	github: "https://github.com/dkhanal112",
	linkedin: "https://www.linkedin.com/in/dkhanal112/",
	gmail: "dkhanal112@gmail.com",
	gitlab: "https://gitlab.com/dkhanal112",
	facebook: "https://www.facebook.com/dkhanal112/",
	twitter: "https://twitter.com/dipesh_don1",
	instagram: "https://www.instagram.com/_khanal_dipesh/"
};

const skills = {
  data: [
    {
      title: "Flutter & Dart",
      imagePath: "flutter1.png",
      skills: [
        "⚡ Developing more accurate and bug free application",
        "⚡ Experience of working with Firebase and Android-Studio",
        "⚡ Complex and creative UI coding with new generic idea"
      ],
      softwareSkills: [
        {
          skillName: "Android-Studio",
          fontAwesomeClassname: "simple-icons:androidstudio",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Visual studio code",
          fontAwesomeClassname: "logos-visual-studio-code",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB"
          }
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "logos-firebase",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB"
          }
        },
      
        {
          skillName: "Database",
          fontAwesomeClassname: "icomoon-free:database",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB"
          }
        },
        
      ]
    },
    {
      title: "Full Stack Development",
      imagePath: "fullstack.svg",
      skills: [
        "⚡ Building resposive website front end using HTML,CSS,JS,PHP",
        "⚡ Developing mobile applications using Flutter, React Native",
        "⚡ Creating application backend in Node, Firebase"
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26"
          }
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6"
          }
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E"
          }
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837"
          }
        },
  
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B"
          }
        }
      ]
    },
    {
      title: "Cloud Infra-Architecture",
      imagePath: "firebase.png",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",

      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6"
          }
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28"
          }
        },
      ]
    },
    {
      title: "UI/UX Design",
      imagePath: "ui_ux_design.svg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience"
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2"
          }
        },
     
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00"
          }
        },
  
      ]
    }
  ]
};

const competitiveSites = {
  competitiveSites: [
  
    {
      siteName: "Coursera",
      iconifyClassname: "cib-coursera",
      style: {
        color: "#1F8ACB"
      },
      profileLink: "https://www.coursera.org/user/b331335290ccc591e89b95dfb471b04f"
    },
    {
      siteName: "Udemy",
      iconifyClassname: "simple-icons:udemy",
      style: {
        color: "#EA5252"
      },
      profileLink: "https://www.udemy.com/user/dipesh-khanal-7/"
    },
  ]
}

const degrees = {
  degrees : [
    {
      title: "MADAN BHANDARI MEMORIAL COLLEGE",
      subtitle: "Bachelor in Computer science and information technology",
      logo_path: "mbm.jpg",
      alt_name: "MBM COLLEGE",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science,Full Stack Development and Flutter.",
      
      ],
      website_link: "http://mbmc.edu.np"
    },
    {
      title: "SHREE SHANTI BHAGAWATI SECONDARY SCHOOL",
      subtitle: "+2 SCIENCE",
      logo_path: "shanti bhagawati.jpg",
      alt_name: "SSBSS",
      duration: " Completion 2017 ",
      descriptions: [
        "⚡ I have studied +2 science with maths as major and Biology as optional subject",
        "⚡ Awarded as college topper" ,
      
      ],
      
      website_link: "https://www.facebook.com/Shree-shanti-bhagwati-higher-secondary-school-682677551877032/"
    },
    {
      title: "GALAXY ACADEMY",
      subtitle: "SCHOL LEAVING CERTIFICATE",
      logo_path: "Galaxy.jpg",
      alt_name: "GA",
      duration: " Completion 2015 ",
      descriptions: [
        "⚡ Awarded as the class topper",
      
      ],
      
      website_link: "https://www.facebook.com/pg/galaxy2053/"
    },
  ]
}

const certifications = {
	certifications: [
		{
			title: "Machine Learning",
			subtitle: "- Andrew Ng",
			logo_path: "stanford_logo.png",
			certificate_link:
				"https://www.coursera.org/account/accomplishments/records/8ZQE38SU7XGF",
			alt_name: "Stanford University",
			color_code: "#8C151599"
		},
		{
			title: "Deep Learning",
			subtitle: "- Andrew Ng",
			logo_path: "deeplearning_ai_logo.png",
			alt_name: "deeplearning.ai",
			color_code: "#00000099"
		},
		
		{
			title: "Flutter",
			subtitle: "- Angela Yu",
			logo_path: "flutterlogo.png",
			certificate_link:
				"hhttps://drive.google.com/file/d/1lZN0IoPtB1M2quLUU3i6rBthtGfQNvcf/view?usp=sharing",
			alt_name: "app",
			color_code: "#1F70C199"
		},
		{
			title: "Web Development",
			subtitle: "- YouAccel Training",
			logo_path: "udemylogo.png",
			certificate_link:
				"https://drive.google.com/file/d/1HuXY7RzCMpvx7yJnBzalWII7KjYdDRR4/view",
			alt_name: "Udemy",
			color_code: "#D83B0199"
		},
		{
			title: "Flutter for you",
			subtitle: "- Udemy",
			logo_path: "flutterlogo.png",
			certificate_link:
				"https://drive.google.com/file/d/1uXVjEQltlOd5wu07RQQ37bBUSs8nnvYj/view",
			alt_name: "IBM",
			color_code: "#1F70C199"
		},
		{
			title: "Python Bootcamp",
			subtitle: "- Dev Nirwal and Frahaan Hussain",
			logo_path: "pythonlogo.png",
			certificate_link:
				"https://drive.google.com/file/d/1S0FdQjx1R4e7jo6ZuaTCA73bHWeW8yin/view",
			alt_name: "Udemy",
			color_code: "#0C9D5899"
		},
		{
			title: "The complete jQuery",
			subtitle: "- Creative Online School",
			logo_path: "jquerylogo.png",
			certificate_link:
				"https://drive.google.com/file/d/1PXdFX_3xRm-qFLyB5aG4wuIohyHC3eNu/view",
			alt_name: "Udemy",
			color_code: "#00000099"
		},
		{
			title: "Practical Web Development",
			subtitle: "- Creative Online School",
			logo_path: "udemylogo.png",  
			certificate_link:
				"https://drive.google.com/file/d/1n9nu1Z12YqigzJ1P6gTBxPMtI-8Tp_e0/view",
			alt_name: "Udemy",
			color_code: "#2A73CC"
		},
	
	]
};

const openSource = {
  /* Your Open Source Section to View Your Github Pinned Projects */
  /* To know how to get github key look at readme.md */

  githubConvertedToken: "Your Github Converted Token",
  githubUserName: "Your Github Username"
};

const bigProjects = {
  /* Some Big Projects You have worked with your company */
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELP TO CREATE THEIR TECH",
  projects: [
    {
      image: "https://drive.google.com/uc?id=1exWn9T6j8TsfDDHJnS3VR66eP6RiGAfY",
      link: "http://saayahealth.com/"
    },
    {
      image: "http://nextu.se/wp-content/themes/nextu/images/og_img.jpg",
      link: "http://nextu.se/"
    }
  ]
};

const achievementSection = {
  /* Your Achievement Section Include Your Certification Talks and More */

  title: "Achievements And Certifications 🏆 ",
  subtitle:
    "Achievements, Certifications Award Letters and Some Cool Stuff that i have done !",

  achivementsCards: [
    {
      title: "Google Code-In Finalist",
      description:
        "First Pakistani to be selected as Google Google-in Finalist from 4000 students from 77 diffrent countries",
      image:
        "https://1.bp.blogspot.com/-Ig-v1tDXZt4/XDODmZvWp1I/AAAAAAAAB0A/KtbFdBPFVQw2O15FekkIR0Yg8MUp--rngCLcBGAs/s1600/GCI%2B-%2BVertical%2B-%2BGray%2BText%2B-%2BWhite%2BBG.png",
      footerLink: [
        {
          name: "Certification",
          url:
            "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url:
            "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url:
            "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      description:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Google_Assistant_logo.svg/1200px-Google_Assistant_logo.svg.png",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url:
            "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      description:
        "Completed Certifcation from SMIT for PWA Web App Development",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu93Bd6LDbxPEOXr-hfLqLYzmHny8c0MJoI3exQP-lwpFLRT7g&s",
      footerLink: [
        { name: "Certification", url: "" },
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ]
};

const blogSection = {
  /* Blogs Section */

  title: "Blogs",
  subtitle:
    "With love for individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  link: "https://ashutoshhathidara.wordpress.com",
  avatar_image_path: "blogs_image.svg"

  
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅",

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/",
      image:
        "https://scontent.fkhi6-1.fna.fbcdn.net/v/t1.0-9/76714032_1730516240415559_1293494289556307968_o.jpg?_nc_cat=103&_nc_ohc=s5f81rdZd6wAQmqUa52YQBA21MjVOy7e-HN9jI7MspRDC-v5ahEouyInQ&_nc_ht=scontent.fkhi6-1.fna&oh=c29141a9a0de7ff2011a8191f5475a78&oe=5E83127C"
    }
  ]
};

// Podcast Section
const podcastSection = {
  title: "Podcast 🎙️",
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ]
};

const contactInfo = {
  title: "Contact Me ☎️",
  subtitle: "Hire me for some project",
  number: "+977-9861882593",
  email_address: "dkhanal112@gmail.com"
};

export {
	theme,
	greeting,
	socialMediaLinks,
	skills,
	competitiveSites,
	degrees,
	certifications,
	openSource,
	bigProjects,
	achievementSection,
	blogSection,
	talkSection,
	podcastSection,
	contactInfo
};
