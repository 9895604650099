import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ExperienceAccordion from '../../containers/experienceAccordion/ExperienceAccordion.js';
// import experience from '../../shared/experience_data.json';
import "./Experience.css";
import { Fade } from "react-reveal";

const experience = {
	title: "Experience",
	subtitle: "Work, Internship and Volunteership",
	description:
		"I learn new stuff every day to be on top of the latest & robust technologies and best practices and looking for an internship get hands-on experience of how things work in an office environment.",
	header_image_path: "experience.svg",
	sections: [
		{
			title: "Work",
			experiences: [
				// {
				// 	title: "Associate AI Engineer",
				// 	company: "Legato Health Technology",
				// 	company_url: "https://legatohealthtech.com/",
				// 	logo_path: "legato_logo.png",
				// 	duration: "June 2020 - PRESENT",
				// 	location: "Hyderabad, Telangana",
				// 	description: "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
				// 	color: "#0879bf"
				// },
			]
		},
		{
			title: "Internships",
			experiences: [
				// {
				// 	title: "Data Science Research Intern",
				// 	company: "Delhivery Pvt. Ltd.",
				// 	company_url: "https://www.delhivery.com/",
				// 	logo_path: "delhivery_logo.png",
				// 	duration: "May 2019 - Sept 2019",
				// 	location: "Gurgaon, Haryana",
				// 	description:
				// 		"I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
				// 	color: "#ee3c26"
				// },

			]
		},
		{
			title: "Volunteerships",
			experiences: [
				// {
				// 	title: "Google Explore ML Facilitator",
				// 	company: "Google",
				// 	company_url: "https://about.google/",
				// 	logo_path: "google_logo.png",
				// 	duration: "June 2019 - April 2020",
				// 	location: "Hyderabad, Telangana",
				// 	description:
				// 		"Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
				// 	color: "#4285F4"
				// },

			]
		}
	]
};

class Experience extends Component {
	render() {
		return (
			<div className="experience-main">
				<Header />
				<div className="basic-experience">
					<Fade bottom duration={1000} distance="40px">
						<div className="experience-heading-div">
							<div className="experience-heading-img-div">
								<img
									src={require(`../../assests/images/${experience["header_image_path"]}`)}
									alt=""
								/>
							</div>
							<div className="experience-heading-text-div">
								<h1 className="experience-heading-text">{experience.title}</h1>
								<h3 className="experience-heading-sub-text">
									{experience["subtitle"]}
								</h3>
								<p className="experience-header-detail-text subTitle">
									{experience["description"]}
								</p>
							</div>
						</div>
					</Fade>
				</div>
				<ExperienceAccordion sections={experience["sections"]} />
				<Footer />
			</div>
		);
	}
}

export default Experience;
